// import React, { Component } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { Layout } from './components/Layout';
import './custom.css';

import { history } from './_helpers';
import { PrivateRoute } from './_components';
import { Home } from './home';
import { Login } from './login';
import { UserManage } from './login';
import { PBAccPage } from "./pbacc";
import { MessagesPage } from "./messages";

export { App };

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout>
                <Home />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/msgs"
          element={
            <PrivateRoute>
              <Layout>
                <MessagesPage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/pbacc"
          element={
            <PrivateRoute>
              <Layout>
                <PBAccPage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/usr-mng"
          element={
            <PrivateRoute>
              <Layout>
                <UserManage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}
