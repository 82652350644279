import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';

import { fetchWrapper } from '../_helpers';

export { PBAccFormDialog };

function PBAccFormDialog({ openAs, handleClose, triggerRefresh }) {
    const [openFormDialog, setOpenFormDialog] = useState(false);

    const [id, setId] = useState(0);
    const [accName, setAccName] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [userIden, setUserIden] = useState("");

    useEffect(() => {
        if (openAs) {
            setOpenFormDialog(true);
            if (openAs !== true) {
                setId(openAs.id);
                setAccName(openAs.accName);
                setAccessToken(openAs.accessToken);
                setUserIden(openAs.userIden);
            }
        } else {
            setId(0);
            setAccName("");
            setAccessToken("");
            setUserIden("");
        }
    }, [openAs]);

    const closeDialog = () => {
        setOpenFormDialog(false)
        handleClose();
    }

    const doSubmit = async () => {
        let body = { accName, accessToken, userIden };
        if (id > 0) { body.id = id };
        const resp = await fetchWrapper.post(`PushBulletAccount`, body);

        if (resp === true) {
            setOpenFormDialog(false)
            handleClose();
            triggerRefresh();
        }
    }

    return (
        <Dialog open={openFormDialog} onClose={closeDialog} >
            <DialogTitle>{id > 0 ? "Edit" : "Add"} Push Bullet Account</DialogTitle>
            <DialogContent>
                {id > 0 &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {id}
                    </Typography>
                }
                <TextField
                    autoFocus
                    margin="dense"
                    id="AccName"
                    label="Account Name"
                    fullWidth
                    variant="standard"
                    value={accName}
                    onChange={(e) => { setAccName(e.target.value) }}
                />
                <TextField
                    margin="dense"
                    id="AccessToken"
                    label="Access Token"
                    fullWidth
                    variant="standard"
                    value={accessToken}
                    onChange={(e) => { setAccessToken(e.target.value) }}
                />
                <TextField
                    margin="dense"
                    id="UserIden"
                    label="User Iden"
                    fullWidth
                    variant="standard"
                    value={userIden}
                    onChange={(e) => { setUserIden(e.target.value) }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{id > 0 ? 'Close' : 'Cancel'}</Button>
                <Button onClick={() => { doSubmit() }}>{id > 0 ? 'Save' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );
}