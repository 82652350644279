import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import UploadFileIcon from "@mui/icons-material/UploadFile";

import * as xlsx from 'xlsx/xlsx.mjs';

import { fetchWrapper } from '../_helpers';

export { AddNewBatchFormDialog };

AddNewBatchFormDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    triggerRefresh: PropTypes.func.isRequired,
};

function AddNewBatchFormDialog({ isOpen, handleClose, triggerRefresh }) {
    const [pbaOpts, setPbaOpts] = useState([]);

    const [loading, setLoading] = useState(false);

    const [fileData, setFileData] = useState([]);
    const [msgContent, setMsgContent] = useState("");
    const [pbaId, setPbaId] = useState('');

    useEffect(() => {
        getPBAccOpts();
    }, []);

    const getPBAccOpts = async () => {
        const resp = await fetchWrapper.get(`PushBulletAccount/opts`);
        setPbaOpts(resp);
    }

    const closeDialog = () => {
        setFileData([]);
        setMsgContent("")
        setPbaId('')

        handleClose();
    }

    const doSubmit = async () => {
        if (msgContent.length < 3 || pbaId < 1 || fileData.length < 1) return;

        let body = { pbAccUse: pbaId, batchMessage: msgContent, sendTos: fileData };

        const resp = await fetchWrapper.post(`AppMsgBatch`, body);

        if (resp === true) {
            closeDialog();
            triggerRefresh();
        }
    }

    const handleFileUpload = (e) => {
        e.preventDefault();
        if (e.target.files) {
            setLoading(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const aoa = xlsx.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
                const flatColA = aoa.map(x => x[0]);
                setFileData(flatColA);
                setLoading(false);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    return (
        <Dialog open={isOpen} onClose={closeDialog} >
            <DialogTitle>Add Message Batch</DialogTitle>
            <DialogContent>
                <Button variant='contained' component="label" endIcon={<UploadFileIcon />} >
                    UPLOAD
                    <input type="file" hidden
                        onChange={handleFileUpload}
                        accept=".xlsx, .xls, .csv" />
                </Button>
                {fileData.length > 0 &&
                    <Box>
                        <Typography>{fileData.length} rows with number</Typography>
                    </Box>
                }
                <TextField
                    margin='normal'
                    id="msgContent"
                    label="Message Content"
                    fullWidth
                    value={msgContent}
                    onChange={(e) => { setMsgContent(e.target.value) }}
                />
                <FormControl fullWidth>
                    <InputLabel>Push Bullet Account</InputLabel>
                    <Select
                        label="Push Bullet Account"
                        value={pbaId}
                        onChange={(e) => { setPbaId(e.target.value) }}
                    >
                        {pbaOpts.map((val, idx) => (
                            <MenuItem value={val.id} key={idx}>
                                {val.accName}
                            </MenuItem>))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
                <Button onClick={() => { doSubmit() }}>Add</Button>
            </DialogActions>
            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>
        </Dialog>
    );
}