import { forwardRef, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import { fetchWrapper } from '../_helpers';

export { BatchMessages };

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BatchMessages({ openBatchMsgs, handleClose }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [msgs, setMsgs] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (openBatchMsgs > 0) {
            setOpen(true);
            getBatchMessages();
        } else {
            setOpen(false);
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openBatchMsgs]);

    useEffect(() => {
        getBatchMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getBatchMessages = async () => {
        if (openBatchMsgs <= 0) return;
        setLoading(true);
        const resp = await fetchWrapper.get(`AppMsgBatch/msg/` + openBatchMsgs + `?page=` + page + `&limit=` + rowsPerPage);
        setMsgs(resp.batchMsgs);
        setCount(resp.count);
        setLoading(false);
    }

    const closeDialog = () => {
        handleClose();
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={closeDialog}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Messages
                    </Typography>
                </Toolbar>
            </AppBar>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size='small'
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Recipient</TableCell>
                            <TableCell>Processed Time</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell>Device</TableCell>
                            <TableCell>GUID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {msgs.map((row, index) => {
                            return (
                                <TableRow key={index}                                >
                                    <TableCell>{row.sendTo}</TableCell>
                                    <TableCell>{row.processedTime}</TableCell>
                                    <TableCell>{row.msgContent}</TableCell>
                                    <TableCell>{row.usedDevIden}</TableCell>
                                    <TableCell>{row.pbapiGuid}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>
        </Dialog>
    );
}
