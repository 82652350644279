import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    TextField,
} from '@mui/material';
import {
    Delete as DeleteIcon,
    Add as AddIcon,
} from '@mui/icons-material';

import { fetchWrapper } from '../_helpers';

export { DevicesFormDialog };

function DevicesFormDialog({ pbads, handleClose, triggerRefresh }) {
    const [openFormDialog, setOpenFormDialog] = useState(false);

    const [pbadata, setPbadata] = useState(null);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        if (pbads) {
            setOpenFormDialog(true);
            setPbadata(pbads);
            setDevices(pbads.pbAccDevices);
        }
    }, [pbads]);

    const addDevice = () => {
        setDevices([...devices, { devName: "", devIden: "" }]);
    }

    const removeDevice = (idx) => {
        let currentDevices = devices;
        currentDevices.splice(idx, 1);
        setDevices([...currentDevices]);
    }

    const closeDialog = () => {
        setOpenFormDialog(false)
        handleClose();
        triggerRefresh();
    }

    const saveDevices = async () => {
        let body = { pbaId: pbadata.id, devices };
        const resp = await fetchWrapper.post(`PushBulletAccount/dev`, body);

        if (resp === true) {
            closeDialog();
        }
    }

    return (
        <Dialog open={openFormDialog} onClose={closeDialog}>
            <DialogTitle>{pbadata?.accName} Devices</DialogTitle>
            <DialogContent>
                <List>
                    {devices.map((val, idx) => (
                        <ListItem disablePadding key={idx}>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <TextField
                                        margin="dense"
                                        id="DevName"
                                        label="Device Name"
                                        fullWidth
                                        size='small'
                                        value={val.devName}
                                        onChange={(e) => {
                                            devices[idx].devName = e.target.value;
                                            setDevices([...devices]);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense"
                                        id="DevIden"
                                        label="Device Iden"
                                        fullWidth
                                        size='small'
                                        value={val.devIden}
                                        onChange={(e) => {
                                            devices[idx].devIden = e.target.value;
                                            setDevices([...devices]);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => { removeDevice(idx) }} size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                    <ListItem disablePadding>
                        <IconButton onClick={addDevice} size="large">
                            <AddIcon />
                        </IconButton>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
                <Button onClick={saveDevices}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
