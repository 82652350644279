import { useEffect, useState } from 'react';
import {
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from '@mui/material';
import {
    Refresh as RefreshIcon,
    Edit as EditIcon,
} from '@mui/icons-material';

import { fetchWrapper } from '../_helpers';
import { PBAccFormDialog } from './PBAccFormDialog';
import { DevicesFormDialog } from './DevicesFormDialog';

export { PBAccPage };

function PBAccPage() {
    const [pbaccs, setPbaccs] = useState([]);

    const [loading, setLoading] = useState(false);

    const [openAddNew, setOpenAddNew] = useState(false);
    const [openEdit, setOpenEdit] = useState(null);

    const [openDevices, setOpenDevices] = useState(null);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        setLoading(true);
        const resp = await fetchWrapper.get(`PushBulletAccount`);
        setPbaccs(resp);
        setLoading(false);
    }

    const clearPBAFormDialog = () => {
        if (openAddNew !== false) setOpenAddNew(false);
        if (openEdit !== null) setOpenEdit(null);
    }

    const clearDevicesFormDialog = () => {
        if (openDevices !== null) setOpenDevices(null);
    }

    const RenderList = ({ data: pbaList }) => {
        return pbaList.map((val, idx) => (
            <Grid item xs={4} key={idx}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {val.id}
                        </Typography>
                        <Typography variant="h5" component="div">
                            {val.accName}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Token: {val.accessToken}
                        </Typography>
                        <Typography variant="body2">
                            Iden: {val.userIden}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Button size="small" onClick={() => { setOpenDevices(val) }}>
                                    {val.pbAccDevices.length} Devices
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <IconButton onClick={() => { setOpenEdit(val) }}>
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        ));
    }

    const TopLayout = () => (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography variant="h5">
                    Push Bullet Accounts
                    <IconButton onClick={() => { getList() }}>
                        <RefreshIcon />
                    </IconButton>
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button variant='contained' onClick={() => { setOpenAddNew(true) }} >
                    Add New
                </Button>
            </Grid>
            <Grid item xs={12}>
                {loading && <LinearProgress />}
            </Grid>
        </Grid>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <TopLayout />
            <br />
            <Grid container spacing={2}>
                <RenderList data={pbaccs} />
            </Grid>
            <PBAccFormDialog
                openAs={openAddNew || openEdit}
                handleClose={clearPBAFormDialog}
                triggerRefresh={getList}
            />
            <DevicesFormDialog
                pbads={openDevices}
                handleClose={clearDevicesFormDialog}
                triggerRefresh={getList}
            />
        </Box>
    );
}
