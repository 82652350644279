import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { fetchWrapper } from '../_helpers';

export { EditServiceStatusDialog };

function EditServiceStatusDialog({ isOpen, handleClose, triggerRefresh }) {
    const [openDialog, setOpenDialog] = useState(false);

    const [run, setRun] = useState(true);
    const [secInterval, setSecInterval] = useState(0);
    const [limit, setLimit] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setRun(isOpen.run);
            setSecInterval(isOpen.secInterval);
            setLimit(isOpen.limit);
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
    }, [isOpen]);

    const closeDialog = () => {
        handleClose();
    }

    const saveChanges = async () => {
        let body = { interval: secInterval, limit: limit, toggle: run };
        const resp = await fetchWrapper.post(`users/editschedule`, body);

        if (resp === true) {
            closeDialog();
            triggerRefresh();
        }
    }

    return (
        <Dialog open={openDialog} onClose={closeDialog} >
            <DialogTitle>Add Message Batch</DialogTitle>
            <DialogContent>
                <Typography variant='caption'>Runtime settings. Reset to default on server restart</Typography>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={run} onClick={() => { setRun(!run); }} />} label="Run" />
                </FormGroup>
                <Typography>
                    Next on {new Date(isOpen?.nextTime).toString()}
                </Typography>
                <TextField
                    margin='normal'
                    id="runtimeInterval"
                    label="Runtime Interval"
                    fullWidth
                    value={secInterval}
                    onChange={(e) => { setSecInterval(e.target.value) }}
                />
                {[...Array(5).keys()].map((v, i) => (
                    <Typography key={i}>
                        {new Date(new Date(isOpen?.nextTime).getTime() + (secInterval * (v + 1)) * 1000).toString()}
                    </Typography>))}
                <TextField
                    margin='normal'
                    id="msgPerDevice"
                    label="Message Per Device"
                    fullWidth
                    value={limit}
                    onChange={(e) => { setLimit(e.target.value) }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
                <Button onClick={() => { saveChanges(); }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}