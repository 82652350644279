import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { fetchWrapper } from '../_helpers';
import { useState } from 'react';

export { UserManage };

function UserManage() {
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
        confirmPw: Yup.string().oneOf([Yup.ref('password')], 'Confirm Password Not Same')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const [error, setError] = useState("");
    const [added, setAdded] = useState(false);

    async function onSubmit({ username, password }) {
        setError("");
        setAdded(false);
        let body = { username, password };
        const resp = await fetchWrapper.post(`users/register`, body);
        if (resp) {
            setAdded(true);
        }
        if (resp !== true) {
            setError(resp.e);
        }
    }

    return (
        <div className="app-container">
            <div className="container pt-4 pb-4">
                <div className="col-md-6 offset-md-3 mt-5">
                    <div className="card">
                        <h4 className="card-header">Add User</h4>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.username?.message}</div>
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input name="confirmPw" type="password" {...register('confirmPw')} className={`form-control ${errors.confirmPw ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.confirmPw?.message}</div>
                                </div>
                                <button disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Add
                                </button>
                                {error !== "" &&
                                    <div className="alert alert-danger mt-3 mb-0">{error}</div>
                                }
                                {added &&
                                    <div class="alert alert-success mt-3 mb-0">Added</div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}