import { useEffect } from 'react';
import {
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from '@mui/material';

import { EditServiceStatusDialog } from './EditServiceStatusDialog';
import { useState } from 'react';
import { fetchWrapper } from '../_helpers';

export { Home };

function Home() {
    const [pageData, setPageData] = useState({});
    const [openDialogData, setOpenDialogData] = useState(null);

    useEffect(() => {
        const getHomePage = async () => {
            const result = await fetchWrapper.get(`users`);
            setPageData(result);
        };
        getHomePage();
    }, []);

    const refresh = async () => {
        const result = await fetchWrapper.get(`users`);
        setPageData(result);
    }

    const openDialog = () => {
        setOpenDialogData({
            run: pageData.isRunning,
            limit: pageData.messagesPerDevice,
            secInterval: pageData.schedulerIntervalInSeconds,
            nextTime: pageData.nextTimeToRun,
        });
    }

    const closeDialog = () => {
        setOpenDialogData(null);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={4} >
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Messages
                            </Typography>
                            <Typography variant="h5" component="div">
                                {pageData.pendingMsg} Pending Messages
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {pageData.pendingBatch} Pending Batches
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} >
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Push Bullet
                            </Typography>
                            <Typography variant="h5" component="div">
                                {pageData.pbAcc} Account
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {pageData.pbaDev} Devices in total
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} >
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Service Status
                            </Typography>
                            <Typography variant="h5" component="div" color={pageData.isRunning ? "primary" : "secondary"}>
                                {pageData.isRunning ? "Running" : "Stopped"}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Run on every {pageData.schedulerIntervalInSeconds} seconds
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Next on {new Date(pageData.nextTimeToRun).toString()}
                            </Typography>
                            <Typography variant="body2">
                                {pageData.messagesPerDevice} sms per device
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => { openDialog(); }}>
                                Change
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <EditServiceStatusDialog
                isOpen={openDialogData}
                handleClose={() => { closeDialog(); }}
                triggerRefresh={() => { refresh(); }}
            />
        </Box>
    );
}
