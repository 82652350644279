import { useEffect, useState } from 'react';
import {
    Button,
    Box,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Refresh as RefreshIcon } from '@mui/icons-material';

import { fetchWrapper } from '../_helpers';
import { AddNewBatchFormDialog } from './AddNewFormDialog';
import { BatchMessages } from './BatchMessages';

export { MessagesPage };

function MessagesPage() {
    const [messages, setMessages] = useState([]);
    const [count, setCount] = useState(0);

    const [loading, setLoading] = useState(false);

    const [openAddNew, setOpenAddNew] = useState(false);
    const [openWithBatchId, setOpenWithBatchId] = useState(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const getList = async () => {
        setLoading(true);
        const resp = await fetchWrapper.get(`AppMsgBatch?page=` + page + `&limit=` + rowsPerPage);
        setMessages(resp.msgBatches);
        setCount(resp.count);
        setLoading(false);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, rowId) => {
        setOpenWithBatchId(rowId)
    };

    const TopLayout = () => (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography variant="h5">
                    Batches
                    <IconButton onClick={() => { getList(); }}>
                        <RefreshIcon />
                    </IconButton>
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button variant='contained' onClick={() => { setOpenAddNew(true) }} >
                    Add New
                </Button>
            </Grid>
            <Grid item xs={12}>
                {loading && <LinearProgress />}
            </Grid>
        </Grid>
    );

    const TableLayout = () => (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Batch</TableCell>
                                <TableCell>Upload TIme</TableCell>
                                <TableCell align='right'>Pending</TableCell>
                                <TableCell align='right'>Sent</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {messages.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        key={index}
                                    >
                                        <TableCell component="th">{row.batchIdentifier}</TableCell>
                                        <TableCell>{row.uploadTime}</TableCell>
                                        <TableCell align="right">{row.pending}</TableCell>
                                        <TableCell align="right">{row.sent}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <TopLayout />
            <br />
            <Grid container spacing={2}>
                <TableLayout />
            </Grid>
            <AddNewBatchFormDialog
                isOpen={openAddNew}
                handleClose={() => { setOpenAddNew(false) }}
                triggerRefresh={() => { getList() }}
            />
            <BatchMessages
                openBatchMsgs={openWithBatchId}
                handleClose={() => { setOpenWithBatchId(0) }}
            />
        </Box>
    );
}